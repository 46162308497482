<template>
  <div :class="classContainer">
    <b-row>
      <b-col class="settings-title" cols="11">
        {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.title') }}
      </b-col>
      <b-col align="right" :class="iconEditClass" cols="1">
        <i class="d-icon-gray icofont icofont-ui-edit"></i>
      </b-col>
    </b-row>
    <concellation-condition-details
      :club="club"
    />
  </div>
</template>
<script>
export default {
  components: {
    ConcellationConditionDetails: () => import('@form/settings/complex-working-conditions/annulation-conditions/DetailForm')
  },
  props: {
    classContainer: {
      type: String,
      default: 'ml-5',
    },
    club: {
      type: Object,
      default: () => {},
    },
    iconEditClass: {
      type: String,
      default: '',
    }
  },
  data: () => ({

  }),
  methods: {

  },
  created () {

  }
}
</script>
<style scoped>

</style>
